import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from './Login'
import Home from './Home'

import './App.css'

function App() {
	return (
		<div className='App'>
			<Switch>
				<Route path='/home'>
					<Home />
				</Route>
				<Route path='/'>
					<Login />
				</Route>
			</Switch>
		</div>
	)
}

export default App
