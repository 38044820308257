import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Notiflix from 'notiflix'
import { Loading } from 'notiflix'

import { styled } from '@mui/material/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@material-ui/core/Typography'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { Colors } from './styles/colors'
import { useCaptcha } from './hook/Captcha'

import './Login.css'

const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: Colors.blueSaurusLight
	},
	'& .MuiOutlinedInput-root': {
		'&.Mui-focused fieldset': {
			borderColor: Colors.blueSaurusDark
		}
	}
})

function LoginPage() {
	const [form, setForm] = useState({
		email: '',
		password: ''
	})
	const [showPassword, setShowPassword] = useState(false)
	const [showPassword2FA, setShowPassword2FA] = useState(false)
	const [errorMessage, setErrorMessage] = useState(false)
	const [hiddeButton, setHiddeButton] = useState(false)

	const [error2fa, setError2fa] = useState(false)
	const [pin2Fa, setPin2Fa] = useState(false)
	const [value2Fa, setValue2Fa] = useState('')
	const [guid, setGuid] = useState()
	const [token, setToken] = useState()

	const history = useHistory()

	const URL = process.env.REACT_APP_URL
	const SIGNATURE = process.env.REACT_APP_SIGNATURE

	const { onSubmit, verifiedToken, verifiedTokenV2, renderWidget } = useCaptcha()

	useEffect(() => {
		if (
			verifiedToken !== undefined &&
			verifiedToken.map.success === true &&
			verifiedToken.map.score >= 0.5
		) {
			signInWithEmailAndPassword()
		} else if (verifiedToken) {
			renderWidget()
		}
		//eslint-disable-next-line
	}, [verifiedToken])

	useEffect(() => {
		if (verifiedTokenV2 !== undefined && verifiedTokenV2.map.success === true) {
			signInWithEmailAndPassword()
		} else if (verifiedTokenV2) {
			Notiflix.Report.failure(
				'Access denied',
				'Sorry we cannot allow your access',
				'Okay'
			)
		}
		//eslint-disable-next-line
	}, [verifiedToken])

	function isTokenValid() {
		return value2Fa.length === 6
	}

	const handleChange = e => {
		if (errorMessage) {
			setErrorMessage(false)
		}
		let value = e.target.value
		setForm({
			...form,
			[e.target.name]: value
		})
	}
	const handleMouseDownPassword = event => {
		event.preventDefault()
	}
	const onHandle2FA = e => {
		if (error2fa) {
			setError2fa(false)
		}
		const value = e.target.value
		setValue2Fa(value)
	}

	const signInWithEmailAndPassword = () => {
		return new Promise((resolve, reject) => {
			Loading.circle()
			axios
				.post(`${URL}/auth/loginV2`, {
					email: form.email,
					pass: form.password,
					signature: SIGNATURE
				})
				.then(response => {
					if (response.status === 200) {
						setGuid(response.data.g)
						setToken(response.data.t)
						setHiddeButton(true)
						resolve(setPin2Fa(true))
					}
				})
				.catch(error => {
					if (error.response.status === 523) {
						setErrorMessage(true)
					}
					console.error('Error on signInWithEmailAndPassword ', error)
				})
				.finally(() => {
					Loading.remove()
				})
		})
	}

	const signInWithTwoFactor = e => {
		e.preventDefault()
		return new Promise((resolve, reject) => {
			Loading.circle()
			axios
				.post(`${URL}/auth/login2faAdmin`, {
					guid: guid,
					pin: value2Fa,
					token: token,
					signature: SIGNATURE
				})
				.then(response => {
					if (response.data.j) {
						setSession(response.data.j)
						resolve(history.push('/home'))
					}
				})
				.catch(error => {
					console.error('Error on signInWithTwoFactor ', error)
				})
				.finally(() => {
					Loading.remove()
				})
		})
	}
	const setSession = access_token => {
		if (access_token) {
			localStorage.setItem('jwt_access_token', access_token)
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
		} else {
			localStorage.removeItem('jwt_access_token')
			delete axios.defaults.headers.common.Authorization
		}
	}

	return (
		<div className='container'>
			<div className='loginContainer'>
				<Card className='card'>
					<CardContent className='cardContent'>
						<img
							className='imageLogo'
							width={228}
							src='logoLogin.png'
							alt='logo'
						/>

						<Typography variant='subtitle1' className='title'>
							Hello, enter your email and password
						</Typography>

						<form
							id='onSubmit-form'
							name='loginForm'
							noValidate
							onSubmit={e => onSubmit(e)}
						>
							<CssTextField
								error={errorMessage}
								helperText={errorMessage ? 'Invalid credentials' : ''}
								className='inputField'
								label='Email'
								autoFocus
								type='email'
								name='email'
								value={form.email}
								onChange={handleChange}
								variant='outlined'
								required
								fullWidth
								inputProps={{
									classes: {
										focused: '#122230'
									}
								}}
							/>

							<CssTextField
								error={errorMessage}
								helperText={errorMessage ? 'Invalid credentials' : ''}
								autoComplete='on'
								className='inputField'
								label='Password'
								type={showPassword ? 'text' : 'password'}
								name='password'
								value={form.password}
								onChange={handleChange}
								variant='outlined'
								required
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={() =>
													setShowPassword(!showPassword)
												}
												onMouseDown={handleMouseDownPassword}
												edge='end'
											>
												{showPassword ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
							<div
								className='g-recaptcha'
								id='widget'
								data-callback='renderWidget'
								//sitekey={SITE_KEY_V2}
							></div>
							{!hiddeButton && (
								<div>
									<Button className='button g-recaptcha' type='submit'>
										Log in
									</Button>
								</div>
							)}
						</form>
						{pin2Fa && (
							<>
								<div>
									<h6>Check your email, we have sent you the code.</h6>
								</div>

								<form
									name='twoFactorForm'
									noValidate
									className='flex flex-col justify-center w-full'
									onSubmit={signInWithTwoFactor}
								>
									<CssTextField
										error={error2fa}
										helperText={error2fa ? 'Invalid pin' : ''}
										type={showPassword2FA ? 'text' : 'password'}
										autoFocus
										className='inputField'
										label='2FA'
										name='twoFactorAuth'
										onChange={onHandle2FA}
										variant='outlined'
										required
										fullWidth
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton
														aria-label='toggle password visibility'
														onClick={() =>
															setShowPassword2FA(
																!showPassword2FA
															)
														}
														onMouseDown={
															handleMouseDownPassword
														}
														edge='end'
													>
														{showPassword ? (
															<VisibilityOff />
														) : (
															<Visibility />
														)}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
									<Button
										variant='contained'
										className='button'
										aria-label='LOG IN'
										disabled={!isTokenValid()}
										type='submit'
									>
										Authenticate
									</Button>
								</form>
							</>
						)}
					</CardContent>
				</Card>
			</div>{' '}
		</div>
	)
}

export default LoginPage
