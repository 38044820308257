import { useEffect, useState } from 'react'
import axios from 'axios'

export const useCaptcha = () => {
	const [reCaptchaToken, setReCaptchaToken] = useState(undefined)
	const [verifiedToken, setVerifiedToken] = useState(undefined)
	const [reCaptchaTokenV2, setReCaptchaTokenV2] = useState(undefined)
	const [verifiedTokenV2, setVerifiedTokenV2] = useState(undefined)

	const SITE_KEY = process.env.REACT_APP_SITE_KEY
	const SITE_KEY_V2 = process.env.REACT_APP_SITE_KEY_V2
	const URL = process.env.REACT_APP_URL

	useEffect(() => {
		if (reCaptchaToken !== undefined) {
			verifyToken()
		} //eslint-disable-next-line
	}, [reCaptchaToken])

	useEffect(() => {
		if (reCaptchaTokenV2 !== undefined) {
			verifyTokenV2()
		} //eslint-disable-next-line
	}, [reCaptchaTokenV2])

	const onSubmit = e => {
		e.preventDefault()
		window.grecaptcha.ready(function () {
			window.grecaptcha
				.execute(SITE_KEY, { action: 'submit' })
				.then(function (token) {
					// console.log('token en onClick ', token)
					setReCaptchaToken(token)
				})
		})
	}

	const verifyToken = () => {
		return new Promise(() => {
			axios
				.post(`${URL}/auth/validateCaptcha`, {
					response: reCaptchaToken
				})
				.then(response => {
					// console.log('response ', response.data)
					if (response.status === 200) {
						setVerifiedToken(response.data)
					}
				})
				.catch(error => {
					if (error.response.status === 500) {
						renderWidget()
					}
					console.error('Error on verifyToken ', error)
				})
		})
	}

	const verifyTokenV2 = () => {
		return new Promise(() => {
			axios
				.post(`${URL}/auth/validateCaptchaV2`, {
					response: reCaptchaTokenV2
				})
				.then(response => {
					// console.log('response ', response.data)
					setVerifiedTokenV2(response.data)
				})
				.catch(error => console.error('Error on verifyToken ', error))
		})
	}

	const renderWidget = () => {
		window.grecaptcha.render('widget', {
			sitekey: SITE_KEY_V2,
			theme: 'ligth',
			callback: verifyCallback
		})
	}
	const verifyCallback = function (response) {
		console.log('response verify token 2', response)
		setReCaptchaTokenV2(response)
	}

	return {
		onSubmit,
		verifiedToken,
		renderWidget,
		verifiedTokenV2
	}
}
