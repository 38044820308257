import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import download from 'downloadjs'
import Notiflix from 'notiflix'
import { Loading } from 'notiflix'
// import download from 'downloadjs'

import { styled } from '@mui/material/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { Colors } from './styles/colors'

import './Home.css'

const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: Colors.blueSaurusLight
	},
	'& .MuiOutlinedInput-root': {
		'&.Mui-focused fieldset': {
			borderColor: Colors.blueSaurusDark
		}
	}
})

const Home = () => {
	const [form, setForm] = useState({
		chid: ''
		// kyc: ''
	})
	const [excell, setExcell] = useState([])
	const [pdf, setPdf] = useState()
	const [errorMessage, setErrorMessage] = useState(false)
	const history = useHistory()

	const URL = process.env.REACT_APP_URL

	const date = new Date().getTime()
	const filename = `${form.chid}-${date}.xls`
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

	let decodedStringAtob = window.atob(excell)

	const downloadManual = () => {
		download(decodedStringAtob, filename, fileType)
	}

	const token = window.localStorage.getItem('jwt_access_token')

	console.log('pdf ', pdf)

	useEffect(() => {
		if (token === null) {
			history.push('/login')
		}
	}, [token, history])

	useEffect(() => {
		if (excell.length > 0) {
			downloadManual()
		} //eslint-disable-next-line
	}, [excell])

	const handleChange = e => {
		setErrorMessage(false)
		let value = e.target.value
		setForm({
			...form,
			[e.target.name]: value
		})
	}

	function handleSubmitChid() {
		// ev.preventDefault()
		Loading.circle()

		axios
			.post(`${URL}/mlro/ip/export`, {
				chid: form.chid
			})
			.then(response => {
				if (response.status === 200) {
					setExcell(response.data)
				}
			})
			.catch(error => {
				if (error && error.response.status === 500) {
					setErrorMessage(true)
				}
				if (error && error.response.status === 401) {
					Notiflix.Report.failure('Session finished', 'Reconnect again', 'OK')
					setSession(null)
					history.push('./login')
				}
				console.error('Error on handleSubmitChid ', error.message)
			})
			.finally(() => {
				Loading.remove()
			})
	}

	function handleSubmitKyc() {
		// ev.preventDefault()

		return new Promise((resolve, reject) => {
			Loading.circle()

			axios
				.post(
					`${URL}/mlro/sauruspfs/temporaryKycPayload`,
					{
						chid: form.chid
					}
					// { responseType: 'blob' }
				)

				.then(response => {
					console.log('RESPONSE', response)
					if (response.status === 200) {
						const blob = new Blob([new Uint8Array(response.data)], {
							type: 'application/pdf'
						})
						download(blob, `${form.chid}-report.pdf`, 'application/pdf')
					}
				})
				.catch(error => {
					if (error && error.response.status === 500) {
						reject(setErrorMessage(true))
					}
					if (error && error.response.status === 401) {
						Notiflix.Report.failure(
							'Session finished',
							'Reconnect again',
							'OK'
						)
						setSession(null)
						history.push('./login')
					}
					console.error('Error on handleSubmitChid ', error.message)
				})
				.finally(() => {
					Loading.remove()
				})
		})
	}

	const setSession = access_token => {
		if (access_token) {
			localStorage.setItem('jwt_access_token', access_token)
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
		} else {
			localStorage.removeItem('jwt_access_token')
			delete axios.defaults.headers.common.Authorization
		}
	}

	const logout = () => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${URL}/auth/logout`)
				.then(response => {
					if (response.status === 200) {
						setSession(null)
						history.push('./login')
					}
				})
				.catch(error => {
					resolve(history.push('/login'))
					console.log('logout: ', error)
				})
		})
	}

	return (
		<div className='container'>
			<div className='loginContainer'>
				<Card className='homeCard'>
					<CardContent className='cardContent'>
						<img width={228} src='logoLogin.png' alt='logo' />

						<Typography variant='subtitle1' className='title'>
							Enter the CHID from which the IP addresses or KYC report is to
							be downloaded.
						</Typography>

						{/* <form name='loginForm' noValidate onSubmit={handleSubmitChid}> */}
						<CssTextField
							error={errorMessage}
							helperText={
								errorMessage
									? 'An error has occurred, check that you enter a correct value and try again.'
									: ''
							}
							className='inputField'
							label='CHID'
							autoFocus
							placeholder='200010514631'
							type='text'
							name='chid'
							value={form.chid}
							onChange={handleChange}
							variant='outlined'
							required
							fullWidth
						/>

						<div className='divButtons'>
							<Button
								variant='contained'
								className='button'
								type='button'
								onClick={() => handleSubmitChid()}
							>
								Download IP
							</Button>
							<Button
								variant='contained'
								className='button'
								type='button'
								onClick={() => handleSubmitKyc()}
							>
								Download KYC
							</Button>
						</div>

						{/* </form> */}
						{/* <Typography variant='subtitle1' className='title'>
							Enter the CHID from which the KYC report is to be downloaded.
						</Typography>
						<form name='loginForm' noValidate onSubmit={handleSubmitKyc}>
							<CssTextField
								autoComplete='on'
								className='inputField'
								label='CHID'
								placeholder='200010514631'
								type='text'
								name='kyc'
								value={form.kyc}
								onChange={handleChange}
								variant='outlined'
								required
								fullWidth
							/>
							<Button variant='contained' className='button' type='submit'>
								Download
							</Button>
						</form> */}
					</CardContent>
				</Card>
			</div>
			<div className='divButton'>
				<Button
					onClick={() => logout()}
					variant='contained'
					className='disconnectButton'
				>
					Disconnect
				</Button>
			</div>
		</div>
	)
}
export default Home
