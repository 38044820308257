// Dado un HUE (matiz) (rojo, rosa, etc.) y una SHADE (tono) (500, 600, etc.) se puede importar el color así:

// import HUE from '@material-ui/core/colors/HUE';

// const color = HUE[SHADE];
import cyan from '@material-ui/core/colors/cyan'
import lightBlue from '@material-ui/core/colors/lightBlue'
import teal from '@material-ui/core/colors/teal' //aguamarina
import orange from '@material-ui/core/colors/orange'
import red from '@material-ui/core/colors/red'

export const Colors = {
	cyanBackground: cyan['A400'],
	cyanHover: cyan['A700'],
	tealBackground: teal['A400'],
	tealHover: teal['A700'],
	darkTealBackground: teal[500],
	darkTealHover: teal[700],
	orangeBackground: orange['A400'],
	orangeHover: orange['A700'],
	redBackground: red['A400'],
	redHover: red['A700'],
	redFinalBackground: red[500],
	redFinalHover: red[700],
	lightBlueBackground: lightBlue['A400'],
	lightBlueHover: lightBlue['A700'],
	blueSaurusLight: '#0B7BA3',
	blueSaurusDark: '#032953',
	greenBackground: '#4caf50'
}
